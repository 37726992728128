import React from "react";
import { graphql } from "gatsby";
import { DateTime } from "luxon";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { PostMetadata } from "../components/PostMetadata";
import { rhythm } from "../utils/typography";
import { PostChip } from "../components/PostChip";

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark;
  const dateParsed = DateTime.fromISO(post.frontmatter.date);

  // hide future posts
  if (dateParsed > DateTime.utc() && process.env.NODE_ENV !== "development") {
    return (
      <Layout location={location} title={`That post isn't ready yet!`}>
        <p>
          You're good at finding things, we'll give you that. But you'll just
          have to wait until this post is public like everyone else!
        </p>
        <p>
          <a href="/">Home page</a>
        </p>
      </Layout>
    );
  }

  const siteTitle = data.site.siteMetadata.title;
  const { previous, next } = pageContext;

  const { featuredImage } = post.frontmatter;
  const featuredImagePath =
    featuredImage && featuredImage.childImageSharp.fixed.src;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        featuredImagePath={featuredImagePath}
        pathname={location.pathname}
      />
      <article className="singlePost">
        <header>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {post.frontmatter.title}
          </h1>
          <PostMetadata showDate={true} post={post} />
        </header>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
      </article>

      <nav className="post-chip-container">
        <h2>What's next?</h2>

        {[previous, next]
          .filter(Boolean)
          .filter(post => !!post.frontmatter.featuredImage)
          .map(post => (
            <PostChip key={post.fields.slug} post={post} />
          ))}
      </nav>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date
        description
        author
        featuredImage {
          childImageSharp {
            fixed(width: 1080) {
              src
            }
          }
        }
      }
    }
  }
`;
